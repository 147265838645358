// Fishnet feeds
export const LIVE_ODDS_LOADING = 'LIVE_ODDS_LOADING';

export const UPDATE_PREMATCH_ODDS = 'UPDATE_PREMATCH_ODDS';
export const UPDATE_PREMATCH_ODDS_FAILED = 'UPDATE_PREMATCH_ODDS_FAILED';

export const UPDATE_LIVE_ODDS = 'UPDATE_LIVE_ODDS';
export const UPDATE_LIVE_ODDS_FAILED = 'UPDATE_LIVE_ODDS_FAILED';

export const UPDATE_MATCH_INFO = 'UPDATE_MATCH_INFO';
export const UPDATE_MATCH_INFO_FAILED = 'UPDATE_MATCH_INFO_FAILED';
export const UPDATE_JERSEYS_INFO = 'UPDATE_JERSEYS_INFO';

export const UPDATE_MATCH_DELTA = 'UPDATE_MATCH_DELTA';
export const UPDATE_MATCH_DELTA_FAILED = 'UPDATE_MATCH_DELTA_FAILED';

export const UPDATE_MATCH_DETAILS = 'UPDATE_MATCH_DETAILS';
export const UPDATE_MATCH_DETAILS_FAILED = 'UPDATE_MATCH_DETAILS_FAILED';

export const UPDATE_TOURNAMENT_LIVETABLE = 'UPDATE_TOURNAMENT_LIVETABLE';
export const UPDATE_TOURNAMENT_LIVETABLE_FAILED = 'UPDATE_TOURNAMENT_LIVETABLE_FAILED';

export const UPDATE_STATS_SEASON_OVERUNDER = 'UPDATE_STATS_SEASON_OVERUNDER';
export const UPDATE_STATS_SEASON_OVERUNDER_FAILED = 'UPDATE_STATS_SEASON_OVERUNDER_FAILED';

export const UPDATE_STATS_SEASON_GOALS = 'UPDATE_STATS_SEASON_GOALS';
export const UPDATE_STATS_SEASON_GOALS_FAILED = 'UPDATE_STATS_SEASON_GOALS_FAILED';

export const UPDATE_STATS_MATCH_SITUATION = 'UPDATE_STATS_MATCH_SITUATION';
export const UPDATE_STATS_MATCH_SITUATION_FAILED = 'UPDATE_STATS_MATCH_SITUATION_FAILED';

export const UPDATE_STATS_TEAM_LASTX = 'UPDATE_STATS_TEAM_LASTX';
export const UPDATE_STATS_TEAM_LASTX_FAILED = 'UPDATE_STATS_TEAM_LASTX_FAILED';

export const UPDATE_MATCH_TIMELINE = 'UPDATE_MATCH_TIMELINE';
export const UPDATE_MATCH_TIMELINE_FAILED = 'UPDATE_MATCH_TIMELINE_FAILED';

export const UPDATE_STATS_MATCH_OVERUNDER = 'UPDATE_STATS_MATCh_OVERUNDER';
export const UPDATE_STATS_MATCH_OVERUNDER_FAILED = 'UPDATE_STATS_MATCH_OVERUNDER_FAILED';

export const UPDATE_STATS_TEAM_VERSUS = 'UPDATE_STATS_TEAM_VERSUS';
export const UPDATE_STATS_TEAM_VERSUS_FAILED = 'UPDATE_STATS_TEAM_VERSUS_FAILED';

export const UPDATE_STATS_TEAM_WIN_PCT = 'UPDATE_STATS_TEAM_WIN_PCT';
export const UPDATE_STATS_TEAM_WIN_PCT_FAILED = 'UPDATE_STATS_TEAM_WIN_PCT_FAILED';

export const UPDATE_STATS_TEAM_INFO = 'UPDATE_STATS_TEAM_INFO';
export const UPDATE_STATS_TEAM_INFO_FAILED = 'UPDATE_STATS_TEAM_INFO_FAILED';

export const UPDATE_MATCH_FUNFACTS = 'UPDATE_MATCH_FUNFACTS';
export const UPDATE_MATCH_FUNFACTS_FAILED = 'UPDATE_MATCH_FUNFACTS_FAILED';
export const UPDATE_MATCH_FUNFACT_IDX = 'UPDATE_MATCH_FUNFACT_IDX';

export const REMOVE_MATCH = 'REMOVE_MATCH';
export const SET_MATCHCLOCK = 'SET_MATCHCLOCK';
export const NEXT_MATCHES_LOAD_SUCCESS = 'NEXT_MATCHES_LOAD_SUCCESS';
