import {
  take,
  call,
  fork,
  put,
  select,
  all,
} from 'redux-saga/effects';
import {
  WordsRequest,
} from 'bcms-api';
import {
  START_LCR,
  BCMS_BETSHOP_CONFIG_LOAD_SUCCESS,
  INITALSTAGE_ENDED,
  SET_AS_MATCHID,
} from 'constants/actions';
import {
  STAGE_PRE_MATCH,
  STAGE_POST_MATCH,
  STAGE_ANNOUNCEMENT,
  STAGE_BREAK,
  STAGE_LIVE,
  STAGE_WORLD_LOTTERY,
} from 'constants/bcms';
import {
  getMatchId,
  getBcmsFeedStage,
  getRefresh,
  getTemporaryStage,
  getLanguage,
  getStreamingFeedsURL,
  getChannelTrailer,
  getMarketingVideo,
  getVideoBreak,
  getUseRefresh,
  getInitialStageEnded,
  getUseReplayAPI,
  getLastMatch,
  getHOTDMatch,
  getIsOperator,
} from 'reducers/index';
import { log } from 'actions/logger';
import { ErrorLog } from 'utils/LoggerItem';
import TranslatorCmp from 'utils/Translator';
import { loadMatchInfo, updateJerseys } from 'sagas/fishnet/matchinfo';
import {
  cacheChannelTrailer,
  cacheMarketingVideo,
} from './videoCaching';
import {
  onSelectorChangeMulti,
  onSelectorChange as onSelChange,
  sagaTryTimeout,
  sagaTryLog,
} from './utilSagas';
import { srLiveReload } from '../actions/srLive';
import { doInitialStage } from './stage';
import {
  setVideoBreak, removeVideoBreak, changeMainMatch, changeLastMatch, changeHOTDMatch,
} from '../actions/bcmsConfig';
import { setActiveTempStage, setActiveStage } from '../actions/stages';
import getOffset from './replay';


function* monitorRefresh(newRefreshTimestamp, prev) {
  if (!(yield select(getUseRefresh))) {
    return;
  }

  if (newRefreshTimestamp > prev) {
    const searchParams = new URLSearchParams(window.location.search); // NOTE: no IE support
    searchParams.set('reload', newRefreshTimestamp);
    window.location.search = searchParams.toString();
  }
}

function* changeSrLiveLang() {
  yield put(srLiveReload('Reload SRLive to force change of language'));
}

function* loadWords(language, userId, prevLang) {
  const baseUrl = yield select(getStreamingFeedsURL);
  const isOperator = yield select(getIsOperator);

  const request = new WordsRequest(baseUrl, userId, language, isOperator);
  TranslatorCmp.loadDictionary(request, language);

  // not first time
  if (prevLang) {
    yield call(changeSrLiveLang, language);
  }
}

function* monitorTempStage(newtempStage, tempStage) {
  if (
    (newtempStage && tempStage && newtempStage.timestamp !== tempStage.timestamp) // dont run on first time
    && newtempStage.stageid !== -1
  ) {
    yield put(setActiveTempStage(newtempStage.stageid));
  }
}

function* monitorVideoBreak(videoBreak) {
  if (videoBreak) {
    yield put(setVideoBreak());
  } else {
    yield put(removeVideoBreak());
  }
}

function* showInitalStage() {
  const tStage = yield select(getTemporaryStage);
  const url = new URL(window.location.href); // NOTE: no IE support
  const lastReload = parseInt(url.searchParams.get('reload'), 10);
  const now = parseInt(Date.now() / 1000, 10);

  const stage = yield select(getBcmsFeedStage);

  // skip ann for WL
  if (tStage.stageid === -1 || lastReload + 5 > now || stage === STAGE_WORLD_LOTTERY) {
    return false;
  }

  return true;
}

function* changeActive(stageId, matchId, didInitialStage) {
  let sId = stageId;
  const singleMatchStage = [
    STAGE_LIVE,
    STAGE_PRE_MATCH,
    STAGE_POST_MATCH,
    STAGE_BREAK,
  ];

  if (!matchId && singleMatchStage.includes(sId)) {
    sId = STAGE_ANNOUNCEMENT;

    const msg = new ErrorLog('Could Not Switch To Stage ' + stageId, 'No MatchId Given');
    yield put(log(msg));
  }
  yield put(setActiveStage(sId, didInitialStage));
}

// early matchinfo call and for sending redux action for changed match
function* monitorMainMatch(mId) {
  if (mId !== 0) {
    const timeoutTime = 1000;
    const qReplay = yield select(getUseReplayAPI);

    if (qReplay && qReplay !== '0') {
      const offset = yield call(getOffset, mId, qReplay);
      yield call(sagaTryTimeout(timeoutTime, loadMatchInfo, mId, offset));
    } else {
      yield call(sagaTryTimeout(timeoutTime, loadMatchInfo, mId));
    }

    yield put(changeMainMatch(mId));
  }
}

function* monitorLastMatch(lastMatchId) {
  if (lastMatchId !== 0) {
    yield put(changeLastMatch(lastMatchId));
  }
}

function* monitorHOTDMatch(hotdMatchId) {
  if (hotdMatchId > 0) {
    yield put(changeHOTDMatch(hotdMatchId));
  }
}
function* onBCMSChange(userId) {
  let [
    matchId, stageId, lang, tStage, didInit, videoBreak, mIdMonitoring, lastMatchId, hotdMatchId,
  ] = Array(11).fill(null);

  let tStamp = parseInt(Date.now() / 1000, 10);
  let action = yield take([BCMS_BETSHOP_CONFIG_LOAD_SUCCESS]);
  yield fork(updateJerseys);

  yield fork(sagaTryLog(cacheChannelTrailer, yield select(getChannelTrailer)));
  yield fork(sagaTryLog(cacheMarketingVideo, yield select(getMarketingVideo)));

  let doingInitialStage = yield call(showInitalStage);
  if (doingInitialStage) {
    yield fork(doInitialStage);
  }

  for (;;) {
    if (action.type === INITALSTAGE_ENDED) {
      doingInitialStage = false;
      didInit = true;
    }

    // run in parallel
    // adding prev at the end gives saga both next and prev
    // onSelectorChange(selector, prev, saga, ...args)
    [lang, tStamp, tStage, videoBreak, mIdMonitoring, lastMatchId, hotdMatchId] = yield all([
      call(onSelChange, getLanguage, lang, loadWords, userId, lang),
      call(onSelChange, getRefresh, tStamp, monitorRefresh, tStamp),
      call(onSelChange, getTemporaryStage, tStage, monitorTempStage, tStage),
      call(onSelChange, getVideoBreak, videoBreak, monitorVideoBreak),
      call(onSelChange, getMatchId, mIdMonitoring, monitorMainMatch), // extra matchId var used here since comparison would fail in onSelectorChangeMulti
      call(onSelChange, getLastMatch, lastMatchId, monitorLastMatch),
      call(onSelChange, getHOTDMatch, hotdMatchId, monitorHOTDMatch),
    ]);

    // TODO: change to something like takeEvery
    if (!doingInitialStage || (yield select(getInitialStageEnded))) { // init stage end action could be swallowed by slow caching
      // if one or both changes run changeActive
      // its like this to avoid double call if both changed
      [stageId, matchId] = yield call(
        onSelectorChangeMulti,
        [getBcmsFeedStage, getMatchId],
        [stageId, matchId],
        changeActive,
        didInit, // avoid extra trailer
      );
      didInit = false;
    }

    action = yield take([
      BCMS_BETSHOP_CONFIG_LOAD_SUCCESS,
      START_LCR,
      INITALSTAGE_ENDED,
      SET_AS_MATCHID,
    ]);
  }
}

export default onBCMSChange;
