import {
  put,
  select,
  apply,
  fork,
  call,
  delay,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getMatchStatus,
  getMatchId,
} from 'reducers';
import { MatchInfoRequest } from 'fishnet-api';
import { updateJerseysInfo, updateMatchInfo, updateMatchInfoFailed } from 'actions/fishnet/index';
import { MATCH_INFO_POLL_INTERVAL } from 'constants/fishnet';
import * as config from 'Config/srlive';
import { sagaCancel, sagaRunning } from '../utilSagas';
import { isEnded, isLive, isNotStarted } from '../../utils/matchstatus';
import { pollFishnet, pollTillResponse, takeMatchUpdate } from '../utilLCR';
import { MIN } from '../../constants/app';

export function* loadMatchInfo(matchId, offset = -1) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : (yield select(getQueryFeed));

  const request = new MatchInfoRequest(matchId, clientAlias, language, qFeed, offset);
  const response = yield apply(request, request.get);

  if (response && !response.error) {
    yield put(updateMatchInfo(response, matchId));
  } else if (response && response.error) {
    yield put(updateMatchInfoFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update MatchInfo failed');
  }
}

export function* updateJerseys(offset = -1) {
  for (; ;) {
    const clientAlias = yield select(getClientAlias);
    const language = yield select(getLanguage);
    const qFeed = offset > 0 ? config.fnReplayUrl : (yield select(getQueryFeed));
    const matchId = yield select(getMatchId);

    const request = new MatchInfoRequest(matchId, clientAlias, language, qFeed, offset);
    const response = yield apply(request, request.get);
    if (response && !response.error) {
      yield put(updateJerseysInfo(response, matchId));
    } else if (response && response.error) {
      yield put(updateMatchInfoFailed(response, matchId));
      throw response;
    } else {
      throw new Error('Update MatchInfo failed');
    }

    yield delay(1 * MIN);
  }
}


// atleast once, poll pregame
function* pollMatchInfo(matchId, offset, pollInterval = MATCH_INFO_POLL_INTERVAL) {
  let status;
  let task;
  yield call(pollTillResponse, { logLevel: 5 }, loadMatchInfo, matchId, offset); // atleast once


  while (true) {
    status = yield select(getMatchStatus, matchId);

    if (isNotStarted(status) && !sagaRunning(task)) {
      // poll prematch
      task = yield fork(
        pollFishnet,
        { pollInterval, logLevel: 5 },
        loadMatchInfo,
        matchId,
        offset,
      );
      // do nothing
    } else if (isLive(status) || isEnded(status)) {
      sagaCancel(task);
      break;
    } else {
      // staus === null, no response from fishnet
    }

    yield call(takeMatchUpdate, matchId);
  }
}

export default pollMatchInfo;
